import { motion } from 'framer-motion'

export const LoadingAnimation = () => {
  return (
    <div className="loading-container">
      <div className="loading-content">
        {/* Line animation container */}
        <motion.div className="line-container">
          <motion.div
            className="line"
            initial={{ width: "0%" }}
            animate={{ 
              width: "100%",
              transition: {
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
              }
            }}
          />
        </motion.div>

        {/* Text animation */}
        <motion.div 
          className="loading-text-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          <motion.span
            className="loading-text"
            animate={{ opacity: [1, 0.5, 1] }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            FABLED.ART
          </motion.span>
        </motion.div>
      </div>
    </div>
  )
} 