import { Suspense, lazy } from 'react'
import { Footer } from './components/Footer'
import { LoadingAnimation } from './components/LoadingAnimation'

const Hero = lazy(() => import('./components/Hero'))

function App() {
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <Hero />
      <Footer />
    </Suspense>
  )
}

export default App